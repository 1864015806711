import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import { saveAs } from 'file-saver';

import { REPORT_STATUS, WorldGroupUuid, defaultGroupNames } from '../../../constants';
import { Container, CustomGroup } from '../../../models';
import { enqueueNotification } from '../../../redux/notifications';
import { updateEfeReportsStatus } from '../../../redux/reports/reports.actions';
import { selectEntitiesMapById } from '../../../selectors';
import { selectCustomGroups } from '../../../selectors/customGroups';
import { selectEfeReportsMeta } from '../../../selectors/reports';
import ChronService from '../../../services/chron';
import HTTPService from '../../../services/http';
import b64toBlob from '../../../utils/b64-to-blob';
import LoadingSpinner from '../../LoadingSpinner';

interface Props {
  spreadsheetReportsDemo: boolean;
  reportType: string;
  reportSourceId: string;
  currencyISO: string;
  container?: Container;
}

interface pollQueryEfeReportProps {
  reportType: string;
  reportSourceId: string;
  currencyISO: string;
  requestId: string;
  container?: Container;
  retriesLeft: number;
  entities: any;
  customGroups: CustomGroup[];
  dispatch: any;
  t: any;
}

const pollQueryEfeReport = async ({
  reportType,
  reportSourceId,
  currencyISO,
  requestId,
  container,
  entities,
  customGroups,
  dispatch,
  t,
  retriesLeft = 10
}: pollQueryEfeReportProps) => {
  if (retriesLeft > 0) {
    ChronService.addTimeout(
      `pollQueryEfeReport_${reportSourceId}`,
      async () => {
        let isReportReady = false;
        let response: null | { status: number; url: string } = null;
        try {
          response = await HTTPService.request({
            apiUrlKey: 'excelReports',
            method: 'get',
            relativePath: `/v1/workbook/${reportType}/${reportSourceId}/${currencyISO}?poll=TRUE&requestId=${requestId}`
          });

          isReportReady = Boolean(response?.status !== 404);
        } catch {}

        if (isReportReady) {
          dispatch(updateEfeReportsStatus({ reportType, reportSourceId, status: REPORT_STATUS.ready }));
          const report = await HTTPService.simpleRequest({
            method: 'get',
            url: String(response?.url)
          });

          const reportSourceName =
            entities[reportSourceId]?.name ??
            customGroups.find((customGroup) => customGroup.groupId === reportSourceId)?.name ??
            defaultGroupNames[reportSourceId] ??
            '';

          saveAs(
            b64toBlob(report),
            `${String(container?.taxYear)} - ${String(container?.containerName)} - Reports (${String(
              reportSourceName
            )}).xlsx`
          );
        } else {
          void pollQueryEfeReport({
            entities,
            container,
            requestId,
            reportType,
            currencyISO,
            customGroups,
            reportSourceId,
            dispatch,
            t,
            retriesLeft: retriesLeft - 1
          });
        }
      },
      5000
    );
  } else {
    dispatch(updateEfeReportsStatus({ reportType, reportSourceId, status: REPORT_STATUS.failed }));
    dispatch(
      enqueueNotification({
        message: t(
          'The report was unable to download.Please try again or reach\nout to provisionsupport@exactera.com for further assistance.'
        ),
        options: {
          variant: 'error',
          style: { whiteSpace: 'pre-line' }
        }
      })
    );
  }
};

const DownloadReportsButton = ({
  spreadsheetReportsDemo,
  reportType,
  reportSourceId,
  currencyISO,
  container
}: Props) => {
  const { t } = useTranslation();
  const entities = useSelector(selectEntitiesMapById);
  const { customGroups } = useSelector(selectCustomGroups);
  const efeReportsMeta = useSelector(selectEfeReportsMeta);
  const dispatch = useDispatch();

  const reportTypeKey = reportType === 'single-entity' ? 'entity' : 'group';

  return efeReportsMeta?.[reportTypeKey]?.[reportSourceId]?.status === REPORT_STATUS.inProgress ? (
    <LoadingSpinner noPadding size="2rem" />
  ) : (
    <Button
      variant="outlined"
      onClick={async () => {
        if (spreadsheetReportsDemo) {
          const { data }: any = await HTTPService.request({
            apiUrlKey: 'reportsApiUrl',
            method: 'get',
            relativePath: `/v1/reports/single-entity/uuid/summary/2020-12-12/USD?targetContainerId=${String(
              WorldGroupUuid
            )}`
          });

          const report: any = await HTTPService.simpleRequest({
            method: 'get',
            url: data.url,
            responseType: 'blob'
          });

          saveAs(report, `2022 Tax Provision - Interco Inc Reporting Package.xlsx`);
          return;
        }

        dispatch(updateEfeReportsStatus({ reportType, reportSourceId, status: REPORT_STATUS.inProgress }));

        const response: any = await HTTPService.request({
          apiUrlKey: 'excelReports',
          method: 'get',
          relativePath: `/v1/workbook/${reportType}/${reportSourceId}/${currencyISO}`
        });

        void pollQueryEfeReport({
          entities,
          container,
          reportType,
          currencyISO,
          customGroups,
          reportSourceId,
          dispatch,
          t,
          retriesLeft: 50,
          requestId: response.requestId
        });
      }}
    >
      {t('Download Reports')}
    </Button>
  );
};

export default DownloadReportsButton;
