import { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Table } from '../../../';
import { useContainers } from '../../../../hooks';
import { RateType, PeriodRates, EntityWithRates, RateUpdate } from '../../../../models';
import { selectCustomExchangeRates, selectDefaultExchangeRates } from '../../../../selectors';
import { EditableNumericCell } from '../../../Table/components';
import { FailedCells, Row, TableProps } from '../../../Table/Table.proptype';
import { renderValue } from '../../../Table/utils';
import { grayOutAllChildrenForRoles } from '../../../UserRoleStylesProvider/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    padding: theme.spacing(1, 1.5)
  },
  table: {
    marginBottom: theme.spacing(1)
  }
}));

const fields = {
  beginning: 'beginning',
  current: 'current',
  end: 'end'
};

type Props = {
  source: {
    name: string;
    taxRates: PeriodRates;
    foreignExchangeRates?: PeriodRates;
    jurisdictionId?: string;
    subJurisdictionId?: string;
    currencyId?: number;
  };
  onRateChange: (rateUpdate: RateUpdate) => void;
  entity: EntityWithRates;
  failedCells: FailedCells;
};

const Rates = ({
  source: { name, taxRates, foreignExchangeRates, jurisdictionId, subJurisdictionId, currencyId },
  entity,
  onRateChange,
  failedCells
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentContainer } = useContainers();
  const { prov4235DisableFxInputForUsd } = useFlags();
  const [open, setOpen] = useState(false);
  const { currencyId: entityCurrencyId, entityId } = entity;
  currencyId = entityCurrencyId;

  const currencyDefaultCurrencyExchangeRates = useSelector(selectDefaultExchangeRates)?.[currencyId] as PeriodRates;
  const currencyCustomCurrencyExchangeRates = useSelector(selectCustomExchangeRates)?.[currencyId];
  const activeJurisdictionId = jurisdictionId ?? subJurisdictionId!;
  const entityTaxRates = entity.taxRates?.[activeJurisdictionId];
  const entityExchangeRates = currencyId ? entity.foreignExchangeRates?.[currencyId] : null;

  const renderRateCells = (field: string) => (row: Row) => {
    const column = row.rateDomain === 'tax' ? { isPercentage: true } : { isNumber: true, shouldNotRoundValue: true };
    const renderOptsConfig = { digits: 5, numberOfDecimals: 4 };

    const isUsdWithUniqueValue =
      prov4235DisableFxInputForUsd &&
      row.rateDomain === 'exchange' &&
      row.currencyId === 150 &&
      (!row[field] || row[field] === 1);
    const shouldBeUneditable = Boolean(currentContainer?.isFinalized) || isUsdWithUniqueValue;

    return shouldBeUneditable ? (
      <div
        title={isUsdWithUniqueValue && !currentContainer?.isFinalized ? 'Uneditable: FX Rate for USD should be 1' : ''}
      >
        <Box display="flex" width={1} justifyContent="right" paddingRight="10px">
          {renderValue(row[field], { field, isNumber: true, shouldNotRoundValue: true }, renderOptsConfig, row)}
        </Box>
      </div>
    ) : (
      <EditableNumericCell
        column={{ ...column, field }}
        row={row}
        failedCells={failedCells}
        renderOpts={renderOptsConfig}
        onCellOrCommentBlur={handleOnCellOrCommentBlur}
      />
    );
  };

  const columns = [
    {
      field: 'rate'
    },
    {
      field: fields.beginning,
      headerName: t('Beginning'),
      isEditable: true,
      isPercentage: true,
      renderCell: renderRateCells(fields.beginning)
    },
    {
      field: fields.current,
      headerName: t('Current'),
      isEditable: true,
      isPercentage: true,
      renderCell: renderRateCells(fields.current)
    },
    {
      field: fields.end,
      headerName: t('End'),
      isEditable: true,
      isPercentage: true,
      renderCell: renderRateCells(fields.end)
    }
  ];
  const rows = useMemo(() => {
    const rateRows = [];

    if (taxRates) {
      rateRows.push({
        rate: t('Tax Rate'),
        rateDomain: 'tax',
        beginning: entityTaxRates?.beginning ?? taxRates.beginning,
        current: entityTaxRates?.current ?? taxRates.current,
        end: entityTaxRates?.end ?? taxRates.end,
        name: `${entityId}.${activeJurisdictionId}.tax`
      });
    }

    if (foreignExchangeRates) {
      const exchangeRates = {
        beginning:
          entityExchangeRates?.beginning ??
          currencyCustomCurrencyExchangeRates?.beginning ??
          currencyDefaultCurrencyExchangeRates?.beginning,
        current:
          entityExchangeRates?.current ??
          currencyCustomCurrencyExchangeRates?.current ??
          currencyDefaultCurrencyExchangeRates?.current ??
          1,
        end:
          entityExchangeRates?.end ??
          currencyCustomCurrencyExchangeRates?.end ??
          currencyDefaultCurrencyExchangeRates?.end
      };

      rateRows.push({
        ...foreignExchangeRates,
        rate: t('Exchange Rate'),
        rateDomain: 'exchange',
        name: `${entityId}.${activeJurisdictionId}.exchange`,
        ...exchangeRates,
        currencyId
      });
    }

    return rateRows;
  }, [
    taxRates,
    foreignExchangeRates,
    t,
    entityTaxRates,
    entityId,
    activeJurisdictionId,
    entityExchangeRates,
    currencyId,
    currencyCustomCurrencyExchangeRates,
    currencyDefaultCurrencyExchangeRates
  ]);

  const handleOnCellOrCommentBlur: TableProps['onCellOrCommentBlur'] = ({ value, column, row }) => {
    const rateType = column.field as RateType;
    const { rateDomain, currencyId } = row;
    const rateUpdate: RateUpdate = {
      entityId,
      jurisdictionId: activeJurisdictionId,
      rateDomain,
      rateType,
      rate: Number(value),
      // @ts-expect-error TODO - we need to figure out if row name can really be undefined
      rowName: row.name,
      // @ts-expect-error TODO - we need to figure out if column field can really be undefined
      columnName: column.field,
      currencyId
    };

    onRateChange(rateUpdate);
  };

  return (
    <Paper
      data-roles-gray-out-all-td={grayOutAllChildrenForRoles.join(' ')}
      className={`${classes.root} Pro-rates`}
      variant="outlined"
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>{name}</Box>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {t('Rates')}
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </Box>
      <Collapse mountOnEnter unmountOnExit in={open}>
        <Table
          columns={columns}
          rows={rows}
          className={classes.table}
          failedCells={failedCells}
          onCellOrCommentBlur={handleOnCellOrCommentBlur}
        />
      </Collapse>
    </Paper>
  );
};

export default Rates;
