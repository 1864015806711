// eslint-disable @typescript-eslint/restrict-template-expressions
import { createReducer } from '@reduxjs/toolkit';

import {
  updateModelMeta,
  updateModelsStatus,
  updateReportMeta,
  updateReportsTimestamps,
  updateEfeReportsStatus
} from './reports.actions';
import { reportsInitialState } from './reports.initialState';

import { REPORT_STATUS } from '../../constants';
import { ReportsReducerStateInterface } from '../../models';
import { translateReportTypeToUI } from '../../utils';
import transformGroupUuid from '../../utils/transformGroupUuid';

export const reportsReducer = createReducer<ReportsReducerStateInterface>(reportsInitialState, (builder) => {
  builder
    .addCase(updateReportMeta, (state, action) => {
      const statusToUpdateKey = `${action.payload.reportName}_${String(
        transformGroupUuid(action.payload.reportSourceId)
      )}_${action.payload.isCurrencyConversion === 1 ? 'true' : 'false'}`;
      const reportTypeKey = translateReportTypeToUI(action.payload.reportType);
      const previousReportMeta = state.reportsMeta[reportTypeKey]?.[statusToUpdateKey];

      const isDifferentModel = previousReportMeta?.modelVersionId !== action.payload.modelVersionId;
      const isSameModelButDifferentStatus =
        previousReportMeta?.modelVersionId === action.payload.modelVersionId &&
        previousReportMeta?.status !== REPORT_STATUS.ready;

      if (state.reportsMeta[reportTypeKey]?.[statusToUpdateKey] === undefined) {
        state.reportsMeta[reportTypeKey][statusToUpdateKey] = action.payload;
      } else if (isDifferentModel || isSameModelButDifferentStatus) {
        const previousReportTimestamp = state.reportsMeta[reportTypeKey][statusToUpdateKey].reportTimestamp;

        state.reportsMeta[reportTypeKey][statusToUpdateKey] = action.payload;

        if (action.payload.reportTimestamp && action.payload.status === REPORT_STATUS.ready) {
          state.reportsMeta[reportTypeKey][statusToUpdateKey].reportTimestamp = action.payload.reportTimestamp;
        } else if (previousReportTimestamp) {
          state.reportsMeta[reportTypeKey][statusToUpdateKey].reportTimestamp = previousReportTimestamp;
        }
      }
    })
    .addCase(updateReportsTimestamps, (state, action) => {
      action.payload.timestamps.forEach((data) => {
        const statusToUpdateKey = `${data.reportName}_${String(transformGroupUuid(action.payload.reportSourceId))}_${
          action.payload.isCurrencyConversion ? 'true' : 'false'
        }`;
        const reportTypeKey = translateReportTypeToUI(action.payload.reportType);

        if (state.reportsMeta[reportTypeKey]?.[statusToUpdateKey] === undefined) {
          state.reportsMeta[reportTypeKey][statusToUpdateKey] = {
            reportSourceId: action.payload.reportSourceId,
            isCurrencyConversion: action.payload.isCurrencyConversion ? 1 : 0,
            modelTimestamp: '',
            reportTimestamp: data.reportTimestamp,
            modelVersionId: '',
            reportName: data.reportName,
            status: REPORT_STATUS.ready,
            reportType: action.payload.reportType
          };
        } else {
          state.reportsMeta[reportTypeKey][statusToUpdateKey] = {
            ...state.reportsMeta[reportTypeKey][statusToUpdateKey],
            reportTimestamp: data.reportTimestamp
          };
        }
      });
    })
    .addCase(updateModelMeta, (state, action) => {
      const statusToUpdateKey = `${transformGroupUuid(action.payload.reportSourceId)}_${String(
        action.payload.isCurrencyConversion === 1 ? 'true' : 'false'
      )}`;

      const reportTypeKey = translateReportTypeToUI(action.payload.reportType);
      const previousModelMeta = state.modelsMeta[reportTypeKey]?.[statusToUpdateKey];

      const isDifferentModel = previousModelMeta?.modelVersionId !== action.payload.modelVersionId;
      const isSameModelButDifferentStatus =
        previousModelMeta?.modelVersionId === action.payload.modelVersionId &&
        previousModelMeta?.status !== REPORT_STATUS.ready;

      if (state.modelsMeta[reportTypeKey]?.[statusToUpdateKey] === undefined) {
        state.modelsMeta[reportTypeKey][statusToUpdateKey] = action.payload;
      } else if (isDifferentModel || isSameModelButDifferentStatus) {
        const previousModelTimestamp = state.modelsMeta[reportTypeKey][statusToUpdateKey].modelTimestamp;

        state.modelsMeta[reportTypeKey][statusToUpdateKey] = action.payload;

        if (action.payload.modelTimestamp && action.payload.status === REPORT_STATUS.ready) {
          state.modelsMeta[reportTypeKey][statusToUpdateKey].modelTimestamp = action.payload.modelTimestamp;
        } else if (previousModelTimestamp) {
          state.modelsMeta[reportTypeKey][statusToUpdateKey].modelTimestamp = previousModelTimestamp;
        }
      }
    })
    .addCase(updateModelsStatus, (state, action) => {
      action.payload.statuses.forEach((modelStatus) => {
        const statusToUpdateKey = `${modelStatus.reportSourceId}_${String(
          modelStatus.isCurrencyConversion ? 'true' : 'false'
        )}`;
        const reportTypeKey = translateReportTypeToUI(action.payload.reportType);

        state.modelsMeta[reportTypeKey][statusToUpdateKey] = {
          reportSourceId: modelStatus.reportSourceId,
          isCurrencyConversion: modelStatus.isCurrencyConversion ? 1 : 0,
          modelTimestamp: modelStatus.modelTimestamp,
          modelVersionId: '',
          containerUuid: '',
          status: REPORT_STATUS.ready,
          reportType: action.payload.reportType
        };
      });
    })
    .addCase(updateEfeReportsStatus, (state, action) => {
      const reportTypeKey = translateReportTypeToUI(action.payload.reportType);
      const modelStatus = action.payload;

      state.efeReportsMeta[reportTypeKey][modelStatus.reportSourceId] = {
        reportSourceId: modelStatus.reportSourceId,
        status: modelStatus.status,
        reportType: action.payload.reportType
      };
    });
});
