import { Box, CircularProgress, CircularProgressProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  progressContainerWithoutPadding: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

interface Props {
  size?: CircularProgressProps['size'];
  noPadding?: boolean;
}

export const LoadingSpinner = (props: Props) => {
  const { noPadding, ...otherProps } = props;
  return (
    <Box className={useStyles({})[noPadding ? 'progressContainerWithoutPadding' : 'progressContainer']}>
      <CircularProgress {...otherProps} />
    </Box>
  );
};

export default LoadingSpinner;
