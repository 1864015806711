/* eslint-disable complexity */ // Due to 'Arrow function has a complexity of 23. Maximum allowed is 20'
import { ReactNode, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, Typography, Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { UploadNotification, GeoMap } from './components';
import { getDashboardBoxes } from './components/GeoMap/utils';
import SummaryAdditionalCard from './components/SummaryAdditionalCard';
import SummaryCard from './components/SummaryCard/SummaryCard';
import { calculateEntityStatusCounts, calculateJurisdictionCounts } from './utils';

import { DASHBOARD_CARD_TYPES } from '../../constants';
import {
  useUploadMetaData,
  useEntities,
  useJurisdictions,
  useCurrencies,
  useMiscReport,
  useCompletionStatus,
  useContainers
} from '../../hooks';
import { JurisdictionById } from '../../models';
import { enqueueNotification } from '../../redux/notifications';
import { setDashboardSelectedCard } from '../../redux/ui';
import {
  selectEntitiesMapByNumber,
  selectParentEntity,
  selectSelectedDashboardCard,
  selectUploadHasLoaded,
  selectUploadIsLoading
} from '../../selectors';
import { selectEntitiesCompletionStatus } from '../../selectors/entitiesCompletionStatus';
import { formatCurrency, formatPercentage } from '../../utils';

const useStyles = makeStyles((theme) => ({
  boxPadding: {
    paddingLeft: theme.spacing(1),
    '&:last-child': {
      paddingRight: theme.spacing(1)
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.secondary.lighter
  },
  // These margins are to compensate the margins defined in the UploadNotification component (to avoid changing its css
  // until we get to the task that actually refactors it)
  notification: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0
  },
  mainContent: {
    display: 'flex',
    gap: '24px',
    height: '100%',
    minHeight: 0
  },
  leftSide: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    overflowY: 'auto',
    gap: '15px'
  },
  rightSide: {
    width: '100%',
    height: '100%'
  }
}));

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entityByNumberMap = useSelector(selectEntitiesMapByNumber);
  const firstEntityNumber = Object.keys(entityByNumberMap)[0];
  useCompletionStatus(firstEntityNumber);
  const { metaData, error, fetchMetaData } = useUploadMetaData();
  const { fileName, error: metaDataError } = metaData;
  const { entities, isLoading: entitiesAreLoading } = useEntities();
  const { jurisdictionById } = useJurisdictions();
  const parentEntity = useSelector(selectParentEntity);
  const { currencyById } = useCurrencies();
  const { currentContainer } = useContainers();
  const entitiesCompletionStatus = useSelector(selectEntitiesCompletionStatus);

  const { completedEntities, inProgressEntities } = calculateEntityStatusCounts(
    entitiesCompletionStatus.entitiesCompletionStatus,
    entityByNumberMap
  );

  const { jurisdictionsCount, subJurisdictionsCount } = calculateJurisdictionCounts(entityByNumberMap);

  const parentEntityCurrency = currencyById[parentEntity?.currencyId ?? -1];
  const currencyForCards = parentEntityCurrency
    ? `${parentEntityCurrency.symbol}${parentEntityCurrency.isoCode}`
    : undefined;

  const { isLoading: isReportLoading, report } = useMiscReport(
    'group',
    'worldwide-consolidated',
    'etr-dashboard',
    'USD'
  );

  const reportValues = report?.values?.[0]?.values ?? [];

  const names = ['etrBox', 'totalOverallProvBox', 'deferredTaxAssetBox', 'ptbiBox'];
  const { etrBoxValue, totalOverallProvBoxValue, deferredTaxAssetBoxValue, ptbiBoxValue } = getDashboardBoxes(
    reportValues,
    names
  );

  const { flagTestLaunchDarklyWeb } = useFlags();
  const classes = useStyles();
  const uploadInProgress = useSelector(selectUploadIsLoading);
  const uploadHasCompleted = useSelector(selectUploadHasLoaded);
  const selectedCard = useSelector(selectSelectedDashboardCard);
  const UploadNotificationBanner = fileName && !error && !metaDataError && <UploadNotification upload={metaData} />;

  const ErrorMessage = (error || metaDataError) && (
    <Typography variant="h3">{t('There was an error with pending reviews. Please try again later')}</Typography>
  );

  useEffect(() => {
    if (flagTestLaunchDarklyWeb) console.log('Launch Darkly is running');
  }, [flagTestLaunchDarklyWeb]);

  useEffect(() => {
    if (uploadInProgress) {
      dispatch(
        enqueueNotification({
          message: 'Upload is in progress. Please wait.',
          options: {
            variant: 'success'
          }
        })
      );
    }
  }, [dispatch, uploadInProgress]);

  useEffect(() => {
    if (uploadHasCompleted) {
      dispatch(
        enqueueNotification({
          message: 'Upload has completed.',
          options: {
            variant: 'success'
          }
        })
      );

      void fetchMetaData();
    }
  }, [dispatch, fetchMetaData, uploadHasCompleted]);

  const summaryCardOnClickHandler = (cardType: keyof typeof DASHBOARD_CARD_TYPES) => {
    dispatch(setDashboardSelectedCard(cardType));
  };

  const shouldShowNotification = Boolean(UploadNotificationBanner || ErrorMessage) && !currentContainer?.isFinalized;
  const defaultCurrencyValue = '$0';
  const defaultPercentageValue = '0.0000%';
  const formatPercentEtrBoxValue = formatPercentage(etrBoxValue, '-', 4);

  return (
    <Box className={classes.container}>
      {shouldShowNotification && (
        <Box className={classes.notification}>{UploadNotificationBanner || (ErrorMessage as ReactNode)}</Box>
      )}

      <Box className={classes.mainContent}>
        <Box className={classes.leftSide}>
          <SummaryCard
            isSelected={selectedCard === 'effectiveTaxRate'}
            isLoading={entitiesAreLoading || isReportLoading}
            title={t('etr')}
            value={etrBoxValue === null ? defaultPercentageValue : formatPercentEtrBoxValue}
            onClick={() => {
              summaryCardOnClickHandler('effectiveTaxRate');
            }}
          />
          <SummaryCard
            isSelected={selectedCard === 'totalProvision'}
            isLoading={entitiesAreLoading || isReportLoading}
            title={t('total-provision')}
            value={totalOverallProvBoxValue === null ? defaultCurrencyValue : formatCurrency(totalOverallProvBoxValue)}
            currency={currencyForCards}
            onClick={() => {
              summaryCardOnClickHandler('totalProvision');
            }}
          />
          <SummaryCard
            isSelected={selectedCard === 'deferredTaxAsset'}
            isLoading={entitiesAreLoading || isReportLoading}
            title={t('deferred-tax-asset')}
            value={deferredTaxAssetBoxValue === null ? defaultCurrencyValue : formatCurrency(deferredTaxAssetBoxValue)}
            currency={currencyForCards}
            onClick={() => {
              summaryCardOnClickHandler('deferredTaxAsset');
            }}
          />
          <SummaryCard
            isSelected={selectedCard === 'preTaxBookIncome'}
            isLoading={entitiesAreLoading || isReportLoading}
            title={t('ptbi')}
            value={ptbiBoxValue === null ? defaultCurrencyValue : formatCurrency(ptbiBoxValue)}
            currency={currencyForCards}
            onClick={() => {
              summaryCardOnClickHandler('preTaxBookIncome');
            }}
          />
          <SummaryAdditionalCard
            isLoading={entitiesAreLoading || isReportLoading}
            title={t('jurisdictions')}
            firstValue={`${jurisdictionsCount} ${t(jurisdictionsCount === 1 ? 'country' : 'countries').toUpperCase()}`}
            secondValue={`${subJurisdictionsCount} ${t(
              subJurisdictionsCount === 1 ? 'state-city' : 'states-cities'
            ).toUpperCase()}`}
          />
          <SummaryAdditionalCard
            isLoading={entitiesAreLoading || isReportLoading}
            title={t('entity-status')}
            firstValue={String(completedEntities)}
            secondValue={String(inProgressEntities)}
          />
        </Box>

        <Box className={classes.rightSide}>
          <GeoMap
            entities={entities}
            jurisdictionById={jurisdictionById as JurisdictionById}
            selectedCard={selectedCard}
            reportValues={reportValues}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
