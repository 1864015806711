import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { GenericRecord } from '../../../../calculations/utils';
import { useContainers } from '../../../../hooks';
import { Currency, FxRatesRecord, FxRateUpdate, RateDomain, RateType, RenderCellProps } from '../../../../models';
import Table from '../../../Table';
import { EditableNumericCell } from '../../../Table/components';
import { TableProps, FailedCells, Row } from '../../../Table/Table.proptype';
import { renderValue } from '../../../Table/utils';
import { disableTableInputForRoles } from '../../../UserRoleStylesProvider/constants';
import { makeStylesName } from '../utils/styles';

const useStyles = makeStyles((theme) => ({
  name: makeStylesName(theme),
  disabledCell: {
    padding: '13px'
  }
}));

const fxFields = {
  beginning: 'beginning.exchange',
  current: 'current.exchange',
  end: 'end.exchange'
};

type RateInfo = [RateType, RateDomain];

export type Props = {
  fxRates: FxRatesRecord;
  currencies: Currency[];
  failedCells: FailedCells;
  onRateChange: (rateUpdate: FxRateUpdate) => void;
};

const CurrencyTable = ({ fxRates = {}, currencies = [], failedCells, onRateChange }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentContainer } = useContainers();
  const { prov4235DisableFxInputForUsd } = useFlags();
  const [expanded, setExpanded] = useState<GenericRecord>({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [rows, setRows] = useState<Row[]>([]);

  const handleOnCellOrCommentBlur: TableProps['onCellOrCommentBlur'] = useCallback(
    ({ value, column, row }) => {
      const [rateType, rateDomain] = column.field.split('.') as RateInfo;
      const rateUpdate: FxRateUpdate = {
        rateType,
        rateDomain,
        rate: Number(value),
        rowName: row.name,
        columnName: column.field,
        currencyId: row.currencyId
      };
      onRateChange(rateUpdate);
    },
    [onRateChange]
  );

  const renderExchangeRateCells = useMemo(() => {
    const cellRenders = [];
    const renderOptsConfig = { digits: 5, numberOfDecimals: 4, isNotPercentage: true };
    for (const field of Object.values(fxFields)) {
      const renderFunc = (row: Row) => {
        const isUsdWithUniqueValue =
          prov4235DisableFxInputForUsd && row.isoCode === 'USD' && (!row[field] || row[field] === 1);
        const shouldBeUneditable = Boolean(currentContainer?.isFinalized) || isUsdWithUniqueValue;
        return shouldBeUneditable ? (
          <div
            className={classes.disabledCell}
            title={
              isUsdWithUniqueValue && !currentContainer?.isFinalized ? 'Uneditable: FX Rate for USD should be 1' : ''
            }
          >
            {renderValue(row[field], { field, isNumber: true, shouldNotRoundValue: true }, renderOptsConfig, row)}
          </div>
        ) : (
          <EditableNumericCell
            column={{ field, isNumber: true, shouldNotRoundValue: true }}
            row={row}
            failedCells={failedCells}
            renderOpts={renderOptsConfig}
            onCellOrCommentBlur={handleOnCellOrCommentBlur}
          />
        );
      };

      cellRenders.push(renderFunc);
    }

    return cellRenders;
  }, [failedCells, handleOnCellOrCommentBlur, currentContainer?.isFinalized, classes, prov4235DisableFxInputForUsd]);

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        filterable: true,
        placeholder: t('Currency Name'),
        divider: true,
        width: '25%',
        renderCell: ({ shouldExpand, id }: RenderCellProps, value: number | string) => (
          <Box className={classes.name}>
            {shouldExpand && !isFiltered && (
              <IconButton
                size="small"
                onClick={() => {
                  setExpanded({ ...expanded, [id]: !expanded[id] });
                }}
              >
                {expanded[id] ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            )}
            <Box>{value}</Box>
          </Box>
        )
      },
      {
        headerGroup: t('Exchange Rates'),
        field: fxFields.beginning,
        headerName: t('Beginning'),
        divider: true,
        isNumber: true,
        renderCell: renderExchangeRateCells[0]
      },
      {
        headerGroup: t('Exchange Rates'),
        field: fxFields.current,
        headerName: t('Current'),
        divider: true,
        isNumber: true,
        renderCell: renderExchangeRateCells[1]
      },
      {
        headerGroup: t('Exchange Rates'),
        field: fxFields.end,
        headerName: t('End'),
        isNumber: true,
        renderCell: renderExchangeRateCells[2]
      }
    ];
  }, [classes, expanded, isFiltered, renderExchangeRateCells, t]);

  useEffect(() => {
    if (!Array.isArray(currencies) || currencies.length === 0) {
      return;
    }

    const newRows = currencies?.map((currency) => ({
      ...currency,
      [fxFields.beginning]: fxRates[currency.currencyId]?.beginning,
      [fxFields.current]: fxRates[currency.currencyId]?.current,
      [fxFields.end]: fxRates[currency.currencyId]?.end
    }));

    setRows(newRows as Row[]);
  }, [currencies, fxRates]);

  return (
    <Box data-roles-disable-table-input={disableTableInputForRoles.join(' ')}>
      <Table
        columns={columns}
        rows={rows}
        noRowLabel={t('No Currencies')}
        isNotEditableShaded={false}
        failedCells={failedCells}
        onFilter={(filters: any) => {
          setIsFiltered(filters.size > 0);
        }}
        onCellOrCommentBlur={handleOnCellOrCommentBlur}
      />
    </Box>
  );
};

export default CurrencyTable;
