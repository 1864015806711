import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import useDocumentTitle from '@tanem/use-document-title';

import { Authentication } from '../../hocs/authenticate';
import { Configuration, ContainersProvider } from '../../hooks';
import theme from '../../theme';
import LaunchDarklyProvider from '../LaunchDarklyProvider';
import NotificationsProvider from '../NotificationsProvider';
import PlatformProvider from '../PlatformProvider';
import Router from '../Router';
import UserRoleStylesProvider from '../UserRoleStylesProvider';

declare global {
  export interface Window {
    // eslint-disable-next-line camelcase
    x_spreadsheet: any;
  }
}

const App = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('Exactera Tax Provision'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Configuration>
        <Authentication>
          <ContainersProvider>
            <LaunchDarklyProvider>
              <NotificationsProvider>
                <PlatformProvider>
                  <UserRoleStylesProvider>
                    <Router />
                  </UserRoleStylesProvider>
                </PlatformProvider>
              </NotificationsProvider>
            </LaunchDarklyProvider>
          </ContainersProvider>
        </Authentication>
      </Configuration>
    </ThemeProvider>
  );
};

export default App;
