import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ReportOutlined from '@material-ui/icons/ReportOutlined';
import { SlideOut } from '@xbs/xbs-common-ui';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { CurrencySelector, ViewReportsButton } from '.';

import DownloadReportsButton from './DownloadReportsButton';
import GroupEntitiesEditor from './GroupEntitiesEditor/index';

import { TableWithComment } from '../..';
import {
  FinalizedContainerStatusesByName,
  REPORT_STATUS,
  UsaGroupId,
  UsaGroupUuid,
  WorldGroupId,
  WorldGroupUuid
} from '../../../constants';
import { useCompletionStatus, useContainers } from '../../../hooks';
import {
  Currency,
  CustomGroupFromApi,
  Entity,
  Group,
  Row,
  CustomGroup,
  EntityWithJurisdictionName,
  GroupWithGroupId
} from '../../../models';
import { ModelStatus } from '../../../models/modelStatus.interface';
import { fetchCustomGroups, createCustomGroup, deleteCustomGroups } from '../../../redux/customGroups';
import { selectDoesUserHaveRole, selectParentEntity } from '../../../selectors';
import { selectCustomGroupsEntities } from '../../../selectors/customGroups';
import { selectEntitiesCompletionStatus } from '../../../selectors/entitiesCompletionStatus';
import { selectModelsMeta } from '../../../selectors/reports';
import theme from '../../../theme';
import transformGroupUuid from '../../../utils/transformGroupUuid';
import BetaChip from '../../BetaChip';
import OutlinedButton from '../../CustomButtons/OutlinedButton/OutlinedButton';
import { isEntityFullyComplete } from '../../EntityDetails/utils';
import LoadingWrapper from '../../LoadingWrapper';
import UpdatingBadge from '../../UpdatingBadge';
import { ProReadOnly, ProReviewer } from '../../UserRoleStylesProvider/constants';
import { getModelStatus } from '../utils';

const useStyles = makeStyles((theme) => ({
  actionsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1.2)
  },
  reportWrapper: {
    padding: theme.spacing(2),
    '& .MuiTableCell-root.MuiTableCell-body:last-child': {
      paddingBottom: '0px',
      borderBottom: 'transparent'
    },
    '& .MuiTableRow-root.new-row.MuiTableRow-hover td': {
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  },
  displayInlineFlex: {
    display: 'inline-flex'
  },
  container: {
    position: 'relative',
    border: `2px solid ${theme.palette.action.focus}`,
    borderRadius: '5px',
    minWidth: '250px',
    marginLeft: '-10px',
    paddingLeft: '8px'
  },
  containerBlur: {
    position: 'relative',
    border: `2px solid transparent ${theme.palette.action.focus}`,
    minWidth: '250px',
    marginLeft: '-10px',
    paddingLeft: '8px'
  },
  containerError: {
    position: 'relative',
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: '5px',
    width: '100%',
    marginLeft: '-10px',
    paddingLeft: '4px'
  },
  message: {
    padding: '5px 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(3.8),
    zIndex: 2
  },
  input: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize,
      width: '306px',
      marginRight: '0px',
      height: '29.5px'
    }
  },
  error: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize,
      color: theme.palette.error.main,
      width: '286px',
      marginRight: '10px',
      height: '29.5px'
    },
    '& .MuiTableRow-root.new-row.MuiTableRow': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  box: {
    display: 'flex',
    justifyContent: 'space between',
    width: '100%'
  },
  icon: {
    color: theme.palette.error.main,
    marginTop: '8px'
  },
  justifyContentCenter: {
    justifyContent: 'center',
    display: 'flex'
  }
}));

type Props = {
  isCustomGroupsLoading: boolean;
  reportCurrencySelections: Record<Entity['id'], Currency>;
  handleReportCurrencyChange: (id: string) => (currencyId: string) => void;
  groups: Array<Group | CustomGroupFromApi>;
  entities: Entity[];
  error: unknown;
};

type CustomGroupRow = {
  containerId: string;
  entityCount: number;
  groupId: string;
  name: string;
  parentEntityId: string;
  isNew: boolean;
  currencies: Currency[];
  isDefaultGroup?: boolean;
  modelStatuses?: ModelStatus[];
};

// FIXME: Reduce complexity
const ByGroupReport = ({
  isCustomGroupsLoading,
  reportCurrencySelections,
  handleReportCurrencyChange,
  groups,
  entities,
  error
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { entityCompletionStatus: _ } = useCompletionStatus(entities?.[0]?.entityNumber);
  const entitiesCompletionStatus = useSelector(selectEntitiesCompletionStatus);
  const customGroupsEntities = useSelector(selectCustomGroupsEntities);

  const [selectedGroup, setSelectedGroup] = useState<CustomGroup | null>(null);
  const { currentContainer } = useContainers();
  const [hasNewRow, setHasNewRow] = useState(false);
  const [groupNames, setGroupNames] = useState<Set<string>>();
  const [isErrorCell, setIsErrorCell] = useState(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    prov3137ReportsStatusRework: isReportsStatusReworkOn,
    spreadsheetReports,
    spreadsheetReportsDemo,
    prov4058HideEfeForInterim,
    prov4087HideEfeForFinalizedBeforeEfeContainers,
    prov4280OnlyEfeReportsView
  } = useFlags();

  const shouldHideEFEBecauseContainerIsInterim =
    prov4058HideEfeForInterim && currentContainer?.isInterimReportingPeriod;
  const shouldHideEFEBecauseContainerWasFinalizedBeforeEFE =
    prov4087HideEfeForFinalizedBeforeEfeContainers &&
    currentContainer?.containerStatus === FinalizedContainerStatusesByName.finalizedBeforeEFE;
  const shouldShowEFE =
    !shouldHideEFEBecauseContainerIsInterim &&
    !shouldHideEFEBecauseContainerWasFinalizedBeforeEFE &&
    (spreadsheetReports || spreadsheetReportsDemo || prov4280OnlyEfeReportsView);
  const parentEntity = useSelector(selectParentEntity);
  const parentEntityId = parentEntity?.entityId ?? '';
  const isReadOnlyUser = useSelector(selectDoesUserHaveRole([ProReadOnly.Name]));
  const isUserReviewer = useSelector(selectDoesUserHaveRole([ProReviewer.Name]));
  const modelsMeta = useSelector(selectModelsMeta);

  const shouldShowWWGroupToReviewer = useMemo(
    () =>
      entities.every((entity) =>
        isEntityFullyComplete(
          entity,
          entitiesCompletionStatus?.entitiesCompletionStatus?.[entity.entityNumber]?.completionStatus
        )
      ),
    [entities, entitiesCompletionStatus?.entitiesCompletionStatus]
  );

  const shouldShowUSGroupToReviewer = useMemo(
    () =>
      entities
        .filter((entity) => (entity as EntityWithJurisdictionName).isoCode === 'USA')
        .every((entity) =>
          isEntityFullyComplete(
            entity,
            entitiesCompletionStatus?.entitiesCompletionStatus?.[entity.entityNumber]?.completionStatus
          )
        ),
    [entities, entitiesCompletionStatus?.entitiesCompletionStatus]
  );

  const groupsWithCompleteEntities = useMemo(
    () =>
      (groups as GroupWithGroupId[]).filter((group) => {
        if (isUserReviewer && group.groupId === WorldGroupId) {
          return shouldShowWWGroupToReviewer;
        }

        if (isUserReviewer && group.groupId === UsaGroupId) {
          return shouldShowUSGroupToReviewer;
        }

        return customGroupsEntities[group.groupId]?.every((entity) =>
          isEntityFullyComplete(
            entity,
            entitiesCompletionStatus?.entitiesCompletionStatus?.[entity.entityNumber]?.completionStatus
          )
        );
      }),
    [
      customGroupsEntities,
      entitiesCompletionStatus?.entitiesCompletionStatus,
      groups,

      isUserReviewer,
      shouldShowUSGroupToReviewer,
      shouldShowWWGroupToReviewer
    ]
  );

  const newRowName = useRef<HTMLInputElement>(null);

  const [rows, setRows] = useState([...groups]);

  useEffect(() => {
    const handleOnDelete = async (groupId: string, groupName: string) => {
      dispatch(deleteCustomGroups({ groupIds: [groupId] }));
      const tempGroupNames = groupNames;
      tempGroupNames?.delete(groupName);
      setGroupNames(tempGroupNames);
    };

    if (groups) {
      setRows(() => [
        ...(isUserReviewer ? groupsWithCompleteEntities : groups).map((group: any) => ({
          ...group,
          groupActions: [
            {
              label: t('Delete'),
              onClick: () => {
                void handleOnDelete(group.groupId, group.name);
              },
              disabled: hasNewRow || currentContainer?.isFinalized
            },
            {
              label: t('Edit Entities'),
              onClick: () => {
                setSelectedGroup(group);
              },
              disabled: hasNewRow
            }
          ]
        })),
        ...(hasNewRow ? [{ isNew: true }] : [])
      ]);
    }
  }, [
    dispatch,
    groupNames,
    groups,
    groupsWithCompleteEntities,
    hasNewRow,
    isUserReviewer,
    currentContainer?.isFinalized,
    t
  ]);

  useEffect(() => {
    if (error) {
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    if (isError) {
      setRows(rows.filter((row: Row) => !row.isNew));
      setHasNewRow(false);
      setIsErrorCell(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const handleCreateGroup = (groupName: string) => {
    dispatch(createCustomGroup({ parentEntityId, groupName: newRowName.current?.value }));
    const tempGroupNames = groupNames;
    tempGroupNames?.add(groupName);
    setGroupNames(tempGroupNames);
  };

  const doesGroupNameExist = (name: string | undefined) => {
    return groupNames?.has(name!);
  };

  const columns = [
    {
      placeholder: t('Name'),
      filterable: true,
      sortable: true,
      field: 'name',
      width: prov4280OnlyEfeReportsView ? '30%' : '20%',
      renderCell: (row: Row) =>
        hasNewRow && row.isNew ? (
          <Box
            role="container"
            className={classnames(
              { [classes.displayInlineFlex]: isErrorCell },
              { [classes.containerError]: isErrorCell }
            )}
          >
            {isErrorCell &&
              (newRowName.current?.value === '' ? (
                <Box role="message" className={classes.message}>
                  {t('Invalid custom group name')}
                </Box>
              ) : (
                <Box role="message" className={classes.message}>
                  {t('The custom group already exists')}
                </Box>
              ))}
            <Box
              className={
                isErrorCell
                  ? classes.box
                  : classnames({ [classes.container]: isFocused }, { [classes.containerBlur]: !isFocused })
              }
            >
              {isErrorCell && <ReportOutlined role="icon" className={classes.icon} />}
              <Input
                disableUnderline
                autoFocus
                fullWidth={!isErrorCell}
                placeholder={isErrorCell ? '' : 'Enter Group Name'}
                className={isErrorCell ? classes.error : classes.input}
                inputRef={newRowName}
                defaultValue={newRowName.current?.value}
                onChange={() => {
                  if (isErrorCell) {
                    setIsErrorCell(false);
                  }
                }}
                onFocus={() => {
                  setIsFocused(true);

                  if (isErrorCell && newRowName.current?.value === '') {
                    setIsErrorCell(false);
                  }
                }}
                onBlur={() => {
                  if (doesGroupNameExist(newRowName.current?.value.trim()) || newRowName.current?.value === '') {
                    setIsErrorCell(true);
                  }

                  setIsFocused(false);
                }}
              />
            </Box>
          </Box>
        ) : (
          row.name
        )
    },
    {
      headerName: t('Entities'),
      field: 'entityCount',
      isNumber: true,
      sortable: true,
      getValue: (row: Row) => row.entityCount,
      renderCell: (row: CustomGroupRow) => {
        const group: CustomGroup = {
          containerId: currentContainer!.containerId,
          entityCount: row.entityCount,
          groupId: row.groupId,
          name: row.name,
          parentEntityId: row.parentEntityId
        };

        const { entityCount, isDefaultGroup, isNew } = row;
        const shouldDisplayAddEntities = entityCount > 0 || isDefaultGroup;
        const shouldButtonBeTransparent = entityCount === 0 && !isDefaultGroup;

        return (
          !isNew && (
            <OutlinedButton
              fillColor={shouldButtonBeTransparent ? 'transparent' : theme.palette.info.main}
              onClick={
                isDefaultGroup
                  ? undefined
                  : () => {
                      handleEntitiesSlideoutOpening(group);
                    }
              }
            >
              <Typography variant="caption" color="primary">
                {shouldDisplayAddEntities
                  ? `${entityCount} ${t(entityCount === 1 ? 'entity' : 'entity_plural').toUpperCase()}`
                  : `+ ${t('add-entities').toUpperCase()}`}
              </Typography>
            </OutlinedButton>
          )
        );
      },
      width: prov4280OnlyEfeReportsView ? '30%' : '20%'
    },
    ...(prov4280OnlyEfeReportsView
      ? []
      : [
          {
            headerName: t('Currency'),
            field: 'currency',
            renderCell: (row: CustomGroupRow) => {
              const usdCurrency = {
                currencyId: 150,
                id: 'United States dollar',
                isoCode: 'USD',
                name: 'USD - United States dollar',
                symbol: '$'
              };
              const handleCurrencySelection = handleReportCurrencyChange(row.groupId);
              return hasNewRow && row.isNew ? (
                <CurrencySelector
                  currencies={[usdCurrency]}
                  value={usdCurrency}
                  handleChange={handleCurrencySelection}
                />
              ) : (
                <CurrencySelector
                  currencies={row.currencies ?? [usdCurrency]}
                  value={reportCurrencySelections[row.groupId] ?? usdCurrency}
                  handleChange={handleCurrencySelection}
                />
              );
            }
          },
          {
            field: 'groupId',
            renderCell: (row: CustomGroupRow, groupId: string) => {
              if (groupId) {
                const currencyISO: string = reportCurrencySelections[groupId]?.isoCode;
                const isCurrencyConversion = currencyISO === 'USD';
                const modelStatus = getModelStatus(
                  modelsMeta.group,
                  transformGroupUuid(row.groupId),
                  isCurrencyConversion
                );
                const modelIsUpdating = modelStatus === REPORT_STATUS.inProgress;

                return (
                  <Box className={classes.actionsCell}>
                    {isReportsStatusReworkOn && modelIsUpdating && <UpdatingBadge />}

                    <ViewReportsButton linkTo={`/reports/groups/${groupId}/${currencyISO}`} />
                  </Box>
                );
              }
            },
            width: '15%'
          }
        ]),
    ...(shouldShowEFE
      ? [
          {
            renderHeader: () => {
              return (
                <Box className={classes.justifyContentCenter}>
                  <BetaChip text="Downloadable Reports" />
                </Box>
              );
            },
            headerName: t('Downloadable Reports'),
            field: 'downloadReport',
            width: prov4280OnlyEfeReportsView ? '30%' : '20%',
            renderCell: (row: CustomGroupRow) => {
              const currencyISO: string = reportCurrencySelections[row.groupId]?.isoCode;
              return (
                <Box className={classes.justifyContentCenter}>
                  <DownloadReportsButton
                    spreadsheetReportsDemo={spreadsheetReportsDemo}
                    reportSourceId={
                      { [UsaGroupId]: UsaGroupUuid, [WorldGroupId]: WorldGroupUuid }[row.groupId] ?? row.groupId
                    }
                    currencyISO={currencyISO}
                    container={currentContainer}
                    reportType="multi-entity"
                  />
                </Box>
              );
            }
          }
        ]
      : [])
  ];

  const handleEntitiesSlideoutOpening = (group: CustomGroup) => {
    setSelectedGroup(group);
  };

  const handleEntitiesSlideoutClosing = () => {
    setSelectedGroup(null);
  };

  const handleGroupEntitiesUpdated = useCallback(() => {
    dispatch(fetchCustomGroups());
  }, [dispatch]);

  return (
    <LoadingWrapper isLoading={isCustomGroupsLoading}>
      <Box className={classes.reportWrapper}>
        <TableWithComment
          displayGroupActions
          columns={columns}
          rows={rows}
          isNotEditableShaded={false}
          newRowButtonLabel={
            currentContainer?.isFinalized ? null : hasNewRow ? t('Save Custom Group') : t('Add New Custom Group')
          }
          handleOnRowDelete={() => {
            if (hasNewRow) {
              setRows(rows.filter((row: Row) => !row.isNew));
              setHasNewRow(false);
              setIsErrorCell(false);
            }
          }}
          onNewRowClick={() => {
            if (hasNewRow) {
              if (isError) {
                setRows(rows.filter((row: Row) => !row.isNew));
                setHasNewRow(false);
                setIsErrorCell(false);
              }

              if (doesGroupNameExist(newRowName.current?.value.trim()) || newRowName.current?.value === '') {
                setIsErrorCell(true);
              } else {
                setIsErrorCell(false);
                handleCreateGroup(newRowName.current?.value?.trim() ?? '');
                setHasNewRow(false);
              }
            } else {
              setHasNewRow(true);
              setRows((rows: any) => [...rows, { isNew: true }]);
            }
          }}
        />
        <SlideOut.Drawer
          isFloating
          title={selectedGroup?.name ?? ''}
          subtitle={isReadOnlyUser ? t('readonly-edit-group-entities') : t('edit-group-entities')}
          titleHeight={100}
          isOpen={Boolean(selectedGroup)}
          isDividerHidden={false}
          onClose={handleEntitiesSlideoutClosing}
        >
          <GroupEntitiesEditor
            currentContainer={currentContainer}
            group={selectedGroup}
            onCancel={handleEntitiesSlideoutClosing}
            onUpdate={handleGroupEntitiesUpdated}
          />
        </SlideOut.Drawer>
      </Box>
    </LoadingWrapper>
  );
};

export default ByGroupReport;
