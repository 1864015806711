import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, makeStyles } from '@material-ui/core';
import { UserRoles } from '@xbs/xbs-enums';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { CurrencySelector, ViewReportsButton } from '.';

import DownloadReportsButton from './DownloadReportsButton';

import { Table } from '../..';
import { FinalizedContainerStatusesByName, REPORT_STATUS, USD_CURRENCY_ID } from '../../../constants';
import { useCompletionStatus, useContainers } from '../../../hooks';
import { Currency, Entity, JurisdictionById, SummaryReportShape } from '../../../models';
import { selectUserRoleUuid } from '../../../selectors';
import { selectEntitiesCompletionStatus } from '../../../selectors/entitiesCompletionStatus';
import { selectModelsMeta } from '../../../selectors/reports';
import { getReportInfoByEntityId } from '../../../utils';
import BetaChip from '../../BetaChip';
import { isEntityFullyComplete } from '../../EntityDetails/utils';
import UpdatingBadge from '../../UpdatingBadge';
import { getModelStatus } from '../utils';

const useStyles = makeStyles((theme) => ({
  actionsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1)
  },
  table: {
    '& .MuiTableRow-root > .MuiTableCell-body:not(:last-child)': {
      borderRight: 'none'
    }
  },
  reportWrapper: {
    padding: theme.spacing(2)
  },
  justifyContentCenter: {
    justifyContent: 'center',
    display: 'flex'
  }
}));

interface FormattedRow {
  entityId: string;
  entityName: string;
  jurisdiction: string;
  ptbi: any;
  effectiveTaxRate: number;
  totalProvision: number;
  deferredTaxAsset: any;
  currency: Currency;
  entityUuid: string;
}

type Props = {
  currencyById: Record<string, Currency>;
  report?: SummaryReportShape;
  entities: Entity[];
  jurisdictionById: JurisdictionById;
  reportCurrencySelections: Record<Entity['id'], Currency>;
  handleReportCurrencyChange: (id: string) => (currencyId: string) => void;
};

const ByEntityReport = ({
  currencyById,
  report,
  entities,
  jurisdictionById,
  reportCurrencySelections,
  handleReportCurrencyChange
}: Props) => {
  const { t } = useTranslation();
  const { actionsCell, table, reportWrapper, justifyContentCenter } = useStyles();
  const { currentContainer } = useContainers();
  const {
    prov3137ReportsStatusRework: isReportsStatusReworkOn,
    spreadsheetReports,
    spreadsheetReportsDemo,
    prov4058HideEfeForInterim,
    prov4087HideEfeForFinalizedBeforeEfeContainers,
    prov4280OnlyEfeReportsView
  } = useFlags();
  const usd = currencyById[USD_CURRENCY_ID];
  const modelsMeta = useSelector(selectModelsMeta);
  const shouldHideEFEBecauseContainerIsInterim =
    prov4058HideEfeForInterim && currentContainer?.isInterimReportingPeriod;
  const shouldHideEFEBecauseContainerWasFinalizedBeforeEFE =
    prov4087HideEfeForFinalizedBeforeEfeContainers &&
    currentContainer?.containerStatus === FinalizedContainerStatusesByName.finalizedBeforeEFE;
  const shouldShowEFE =
    !shouldHideEFEBecauseContainerIsInterim &&
    !shouldHideEFEBecauseContainerWasFinalizedBeforeEFE &&
    (spreadsheetReports || spreadsheetReportsDemo || prov4280OnlyEfeReportsView);

  const columns = [
    {
      placeholder: t('Entity Number'),
      filterable: true,
      sortable: true,
      field: 'entityId',
      width: prov4280OnlyEfeReportsView ? '30%' : '20%'
    },
    {
      placeholder: t('Entity Name'),
      filterable: true,
      sortable: true,
      field: 'entityName',
      width: prov4280OnlyEfeReportsView ? '30%' : '20%'
    },
    {
      headerName: t('Jurisdiction'),
      field: 'jurisdiction',
      sortable: true,
      width: prov4280OnlyEfeReportsView ? '15%' : '20%'
    },
    ...(prov4280OnlyEfeReportsView
      ? []
      : [
          {
            headerName: t('Currency'),
            field: 'currency',
            renderCell: (row: FormattedRow) => {
              const handleCurrencySelection = handleReportCurrencyChange(row.entityUuid);
              const currencies = row.currency === usd ? [usd] : [row.currency, usd];
              return (
                <CurrencySelector
                  currencies={currencies}
                  value={reportCurrencySelections[row.entityUuid]}
                  handleChange={handleCurrencySelection}
                />
              );
            }
          },
          {
            field: 'entityId',
            width: '15%',
            renderCell: (row: any, entityId: string) => {
              const currencyISO: string = reportCurrencySelections[row.entityUuid].isoCode;
              const isCurrencyConversion = currencyISO === 'USD';
              const modelStatus = getModelStatus(modelsMeta.entity, row.entityUuid, isCurrencyConversion);
              const modelIsUpdating = modelStatus === REPORT_STATUS.inProgress;

              return (
                <Box className={actionsCell}>
                  {isReportsStatusReworkOn && modelIsUpdating && <UpdatingBadge />}
                  <ViewReportsButton linkTo={`/reports/entities/${entityId}/${currencyISO}`} />
                </Box>
              );
            }
          }
        ]),
    ...(shouldShowEFE
      ? [
          {
            renderHeader: () => {
              return (
                <Box className={justifyContentCenter}>
                  <BetaChip text="Downloadable Reports" />
                </Box>
              );
            },
            headerName: t('Downloadable Reports'),
            field: 'downloadReport',
            width: prov4280OnlyEfeReportsView ? '25%' : '20%',
            renderCell: (row: any) => {
              const currencyISO: string = reportCurrencySelections[row.entityUuid].isoCode;
              return (
                <Box className={justifyContentCenter}>
                  <DownloadReportsButton
                    spreadsheetReportsDemo={spreadsheetReportsDemo}
                    reportSourceId={row.entityUuid}
                    currencyISO={currencyISO}
                    container={currentContainer}
                    reportType="single-entity"
                  />
                </Box>
              );
            }
          }
        ]
      : [])
  ];

  const { entityCompletionStatus: _ } = useCompletionStatus(entities?.[0]?.entityNumber);

  const roleUuid = useSelector(selectUserRoleUuid);
  const isUserReviewer = roleUuid === UserRoles.ByName.ProReviewer.RoleUuid;

  const entitiesCompletionStatus = useSelector(selectEntitiesCompletionStatus);

  const formattedEntities = useMemo(() => {
    if (!Array.isArray(entities)) {
      return [];
    }

    const formattedEntities = [];

    for (const entity of entities) {
      const completionStatus =
        entitiesCompletionStatus?.entitiesCompletionStatus?.[entity.entityNumber]?.completionStatus ?? {};
      if (isUserReviewer && !isEntityFullyComplete(entity, completionStatus)) {
        continue;
      }

      const jurisdiction = jurisdictionById[entity.jurisdictionId];

      const { effectiveTaxRateRaw, totalProvision, deferredRaw } = getReportInfoByEntityId(report, entity.id);

      const formattedEntity = {
        entityId: entity.id,
        entityUuid: entity.entityId,
        entityName: entity.name,
        jurisdiction: `${jurisdiction.name}${
          entity.subJurisdictionIds.length > 0 ? ` + ${entity.subJurisdictionIds.length}` : ''
        }`,
        ptbi: effectiveTaxRateRaw?.ptbi,
        effectiveTaxRate: totalProvision / (effectiveTaxRateRaw?.ptbi || 1),
        totalProvision,
        // FIXME : Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        deferredTaxAsset: (deferredRaw?.federal || 0) + (deferredRaw?.state || 0),
        currency: currencyById[entity.currencyId],
        modelStatuses: entity.modelStatuses
      };

      formattedEntities.push(formattedEntity);
    }

    return formattedEntities;
  }, [currencyById, entities, entitiesCompletionStatus, isUserReviewer, jurisdictionById, report]);

  return (
    <Box className={reportWrapper}>
      <Table className={table} columns={columns} rows={formattedEntities} isNotEditableShaded={false} />
    </Box>
  );
};

export default ByEntityReport;
