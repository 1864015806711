import { createAction } from '@reduxjs/toolkit';

export type ReportMeta = {
  isCurrencyConversion: number;
  modelTimestamp: string;
  reportTimestamp?: string;
  modelVersionId: string;
  reportName: string;
  reportSourceId: string;
  status: number;
  reportType: string;
};

export type EfeReportMeta = {
  reportTimestamp?: string;
  reportSourceId: string;
  status: number;
  reportType: string;
};

export type ModelMeta = {
  isCurrencyConversion: number;
  containerUuid: string;
  modelTimestamp?: string;
  modelVersionId: string;
  reportSourceId: string;
  status: number;
  reportType: string;
};

type UpdateReportTimestampsPayload = {
  isCurrencyConversion: boolean;
  reportSourceId: ReportMeta['reportSourceId'];
  reportType: ReportMeta['reportType'];
  timestamps: Array<{
    reportName: ReportMeta['reportName'];
    reportTimestamp: ReportMeta['reportTimestamp'];
  }>;
};

type updateModelsStatusPayload = {
  reportType: ReportMeta['reportType'];
  statuses: Array<{
    reportSourceId: ReportMeta['reportSourceId'];
    isCurrencyConversion: boolean;
    status: ReportMeta['status'];
    modelTimestamp: ReportMeta['modelTimestamp'];
  }>;
};

type updateEfeReportStatusPayload = {
  reportType: EfeReportMeta['reportType'];
  reportSourceId: EfeReportMeta['reportSourceId'];
  status: EfeReportMeta['status'];
};

export const updateReportMeta = createAction<ReportMeta>('reports/updateReportMeta');
export const updateReportsTimestamps = createAction<UpdateReportTimestampsPayload>('reports/updateReportsTimestamps');
export const updateModelMeta = createAction<ModelMeta>('reports/updateModelMeta');
export const updateModelsStatus = createAction<updateModelsStatusPayload>('reports/updateModelsStatus');
export const updateEfeReportsStatus = createAction<updateEfeReportStatusPayload>('reports/updateEfeReportsStatus');
